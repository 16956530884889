import './src/scss/theme.scss'
import('../../src/js/app')
require('owl.carousel')

$(document).ready(function () {
    $('.suggestion-slider .owl-carousel').owlCarousel({
        margin: 30,
        autoplay: false,
        autoplayHoverPause: false,
        slideTransition: 'linear',
        autoplaySpeed: 6000,
        smartSpeed: 6000,
        dots: false,
        mouseDrag: true,
        touchDrag: false,
        pullDrag: false,
        freeDrag: false,
        nav: true,
        loop: true,
        navClass: ['prev','next'],
        navText : ["<i class='fa fa-arrow-left d-flex'></i>","<i class='fa fa-arrow-right d-flex'></i>"],
        responsive: {
            0: {
                stagePadding: 20,
                margin: 5,
                items: 1,
            },
            800: {
                stagePadding: 50,
                items: 2,
            },
            1350: {
                items: 2,
                stagePadding: 150
            },
            1750: {
                items: 3,
                stagePadding: 150
            }
        }
    })
    $('.cta-slider .owl-carousel').owlCarousel({
        margin: 5,
        autoplay: false,
        autoplayHoverPause: false,
        autoplaySpeed: 6000,
        dots: false,
        mouseDrag: true,
        touchDrag: false,
        pullDrag: false,
        freeDrag: false,
        nav: true,
        loop: true,
        stagePadding: 0,
        navClass: ['prev-alt','next'],
        navText : ["<i class='fa fa-arrow-left d-flex'></i>","<i class='fa fa-arrow-right d-flex'></i>"],
        responsive: {
            0: {
                margin: 10,
                items: 1,
            },
            700: {
                items: 1,
            },
            1200: {
                items: 2,
            }
        }
    })
    $('.review-slider .owl-carousel').owlCarousel({
        margin: 0,
        items: 1,
        dots: false,
        nav: true,
        loop: true,
        navClass: ['prev-alt','next'],
        navText : ["<i class='fa fa-arrow-left d-flex'></i>","<i class='fa fa-arrow-right d-flex'></i>"],
    })
})
